<template>
  <div>
    <b-card title="Kick start your project 🚀">
      <b-card-text>All the best for your new project.</b-card-text>
      <b-card-text>This project shoould be started soon :)</b-card-text>
    </b-card>

    <b-card title="JWT already here? 🔒">
      <b-card-text>
        JWT flow has been carefully intergrated so you can implement JWT with
        ease and with minimum efforts.
      </b-card-text>
      <b-card-text>
        Please read our JWT Documentation to get more out of JWT authentication.
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style></style>
